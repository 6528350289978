import Ract from "react";
import Axios from "axios";
import toast from "react-hot-toast";
import { ServiceUrl } from "../utils/serviceUrl";
import callApi from "../utils/callApi";
import { DB_POSTFIX, jsonToQuery } from "../utils";

export const listSettingsFields = async () => {
  return new Promise((resolve, reject) => {
    Axios.get(ServiceUrl.SETTINGS + "&sadb=" + DB_POSTFIX)
      .then(function ({ data }) {
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        return [];
      });
  });
};

export const _ListGallery = async (searchData = false) => {
  let url = ServiceUrl.LIST_GALLERY + "?sadb=" + DB_POSTFIX;
  if (searchData) url += "?" + jsonToQuery(searchData);
  return callApi(url);
};

export const _HOME_PAGE_SETTINGS = async (searchData = false) => {
  let url = ServiceUrl.HOME_PAGE_SETTINGS + "?sadb=" + DB_POSTFIX;
  return callApi(url, "post", searchData && searchData);
};

export const _GET_PAGE = async (slug, searchData = false) => {
  let url = ServiceUrl.GET_PAGE + "?sadb=" + DB_POSTFIX;
  let num = Number.isFinite(Number(slug));

  if (num) url += "&id=" + slug;
  else url += "&slug=" + slug;

  return callApi(url, "post", searchData && searchData);
};
