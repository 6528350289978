import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

import PsContext from "./index";
import { getLs, setLs } from "../utils";
import { listSettingsFields } from "../models/fieldSettings";

const PsContextProvider = (props) => {
  const [windowSize, setWindowSize] = useState(null);
  const [regionalLang, setRegionalLang] = useState("english");
  const [state, setState] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      // Perform actions on window resize
      //setWindowSize([window.innerWidth, window.innerHeight]);
      setWindowSize(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setWindowSize(window.innerWidth);
    setRegionalLang(getLs("site_lang") || "english");
  }, []);

  const getSettings = () => {
    try {
      let set = getLs("adm_settings");
      if (set && set !== undefined) {
        set = set.trim();
        return JSON.parse(getLs("adm_settings"));
      } else {
        return [];
      }
    } catch (er) {
      console.log(er);
      return [];
    }
  };

  const [backgroundProcess, setBackgroundProcess] = useState(false);

  const [settings, setSettings] = useState(getSettings());

  const loadSettings = () => {
    listSettingsFields().then((res) => {
      setLs("adm_settings", JSON.stringify(res));
      setSettings(res);
    });
  };

  const settingValue = (key) => {
    let m = settings && settings.find((item) => item.field_name == key);
    return m && m.field_name ? m.field_value : false;
  };

  const setStorage = (storageName, storageValue) => {
    setLs(storageName, storageValue);
  };

  const getStorage = (storageName) => {
    return getLs(storageName); /* && getLs(storageName) !== "undefined"
      ? getLs(storageName)
      : null;*/
  };

  const setRegionalLanguage = (lang) => {
    setLs("site_lang", lang);
    setRegionalLang(lang);
    forceUpdate();
  };

  const isRegionalLanguage = () => {
    if (regionalLang == "tamil") return true;
    else return false;
  };

  const forceUpdate = useCallback(() => setState({}), []);

  return (
    <PsContext.Provider
      value={{
        setBackgroundProcess: setBackgroundProcess,
        backgroundProcess: backgroundProcess,
        loadSettings: loadSettings,
        settingValue: settingValue,
        windowSize: windowSize,
        setStorage: setStorage,
        getStorage: getStorage,
        setRegionalLanguage: setRegionalLanguage,
        regionalLang: regionalLang,
        isRegionalLanguage: isRegionalLanguage,
      }}
    >
      {props.children}
    </PsContext.Provider>
  );
};

export default PsContextProvider;
